<template>
  <div class="home-container">
    <div class="header" style="display:block;">
      <!-- <user-info
        class="header-item user-info"
        :user-id="userId"
        :user-name="userName"
        :user-avatar="userAvatar"
        :room-infos="roomInfo"
        @log-out="handleLogOut"
      ></user-info> -->
    </div>
    <div class="home-herd">
      <!-- <div class="header" style="display:block;">
      <user-info
        class="header-item user-info"
        :user-id="userId"
        :user-name="userName"
        :user-avatar="userAvatar"
        :room-infos="roomInfo"
        @log-out="handleLogOut"
      ></user-info>
    </div> -->
      <div class="admin-commnet">
        <div class="admin-commnet-box">
          <user-info
        class="header-item user-info"
        :user-id="userId"
        :user-name="userName"
        :user-avatar="userAvatar"
        :room-infos="roomInfo"
        @log-out="handleLogOut"
        style="margin-bottom: 20px;"
      ></user-info>
          <stream-preview ref="streamPreviewRef"></stream-preview>

        </div>
        <room-control
          :given-room-id="givenRoomId"
          :major-room-id="majorRoomId"
          @create-room="handleCreateRoom"
          @enter-room="handleEnterRoom"
        ></room-control>
      </div>
    </div>
    <div class="footer-beian">
       <a target="_blank" href="https://beian.miit.gov.cn/">
        <img src="" style="float:left;"/>
        <p>Copyright © 2023 蜀ICP备2023023809号-1</p>
      </a>
    </div>
  </div>
</template>

<script>
import UserInfo from '@/TUIRoom/components/RoomHeader/UserInfo.vue';
import StreamPreview from '@/TUIRoom/components/RoomHome/StreamPreview.vue';
import RoomControl from '@/TUIRoom/components/RoomHome/RoomControl.vue';
import TUIRoomCore from '@/TUIRoom/tui-room-core';
import StringUtils from "@/putils/StringUtils";
import { USER_ROLE_WATCH,USER_ROLE_MAJOR,USER_ROLE_STARTV } from "@/constants/localKeys"
import { mapState } from 'vuex';
import { Message } from 'element-ui';
import { MESSAGE_DURATION } from '@/constants/message';
import {RoomStatus} from "@/constants/render";
import liveConfigInfo from '@/config/liveConfigInfo';
import {  getRoodDetail } from '@/api/player/player';
import StorageUtils from '@/putils/StorageUtils';
import { USER_DATA_KEY } from '@/constants/localKeys';
export default {
  name: 'admin',
  components: { UserInfo, StreamPreview, RoomControl },
  mixins: [liveConfigInfo],
  data() {
    return {
      givenRoomId: '',
      majorRoomId: '',
      basicInfo: null,
      userName: '',
      userAvatar: '',
      userRole: '',
      userId: '',
      imuserId: '',
      roomId:'',
    };
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
      userSig:state => state.userInfo.userSig,
      sdkAppId: state => state.sdkAppId,
      shareUserSig: state => state.userInfo.shareUserSig,
      shareuserId: state => `share_${state.userInfo.userId}`,
      playerDomain:"playerDomain",
      roomInfo:"roomInfo",
      anchorUserInfo:"anchorUserInfo",
    }),
  },
  async created(){
    if (!StringUtils.isEmpty(this.$route.query.userId)){
       this.getCacheUserInfo(this.$route.query.userId,);
    }
  },
  async mounted() {
    await this.getCacheRoomInfo(this.$route.query.roomId,this.$route.query.userId)
    if (StringUtils.isObjEqual(this.userInfo.userRole,USER_ROLE_WATCH)){
      Message({
        type: 'warning',
        message: `该用户没有权限进入管理页面`,
        duration: MESSAGE_DURATION.NORMAL,
      });
      this.routerLogin();
      return;
    }
    //判断房间是否正常状态
    if (StringUtils.isObjEqual(this.roomInfo.roomStatus,RoomStatus.ROOM_FORBIDDEN)){
      Message({
        type: 'warning',
        message: `该房间已被封禁，请联系商户人员！`,
        duration: MESSAGE_DURATION.LONG,
      });
      return;
    }
    if (StringUtils.isObjEqual(this.userInfo.userRole,USER_ROLE_STARTV) &&
      StringUtils.isObjEqual(this.roomInfo.roomStatus,RoomStatus.ROOM_LIVE_OVER)){
      Message({
        type: 'warning',
        message: `该直播已结束，请联系主持人！`,
        duration: MESSAGE_DURATION.LONG,
      });
      return;
    }
    //判断是否是主持人
    if (!StringUtils.isObjEqual(this.userInfo.userRole,USER_ROLE_MAJOR)) {
      this.givenRoomId = this.$route.query.roomId || '';
    }else{
      this.majorRoomId = this.$route.query.roomId || '';
    }

    this.basicInfo = this.userInfo;
    this.userName = this.userInfo.userName;
    this.userAvatar = this.userInfo.userAvatar;
    this.userRole = this.userInfo.userRole;
    this.userId = this.userInfo.userId;
    this.imUserId = this.userInfo.imUserId;
    this.basicInfo.roomInfos = this.roomInfo;
    this.basicInfo.sdkAppId = this.sdkAppId;
    if (this.basicInfo) {
      sessionStorage.setItem('tuiRoom-userInfo', JSON.stringify(this.basicInfo));
      const { sdkAppId, userId,userRole, userSig } = this.basicInfo;
      // 登录 TUIRoomCore, 只有登录 TUIRoomCore 之后，才可以使用 TUIRoomCore.checkRoomExistence 方法
      await TUIRoomCore.login(sdkAppId, userId, userRole, userSig);
    }
    let roomId = this.roomInfo.roomId;
    if (StringUtils.isObjEqual(this.userInfo.userRole,USER_ROLE_MAJOR)) {
      // this.handleEnterRoom(roomId);
    }
  },
  methods: {
    setTUIRoomData(action) {
      const roomParam = this.$refs.streamPreviewRef.getRoomParam();
      const roomData = {
        action,
        roomMode: 'ApplySpeech',
        roomParam,
      };
      sessionStorage.setItem('tuiRoom-roomInfo', JSON.stringify(roomData));
    },
    // 创建房间时生成房间号
    async generateRoomId() {
      // const roomId = Math.ceil(Math.random() * 1000000);
      // const isRoomExist = await TUIRoomCore.checkRoomExistence(roomId);
      // if (isRoomExist) {
      //   return await this.generateRoomId();
      // }
      // return roomId;
    },
    // 处理点击【创建房间】
    async handleCreateRoom() {
      this.setTUIRoomData('createRoom');
      const roomId = await this.generateRoomId();
      this.$router.push({ path: 'room', query: { roomId } });
    },
    // 处理点击【进入房间】
    async handleEnterRoom(roomId) {
      const isRoomExist = await TUIRoomCore.checkRoomExistence(roomId);
      if (!isRoomExist && this.userInfo.userRole == USER_ROLE_STARTV){
        Message({
          type: 'warning',
          message: `该房间未开始直播，请联系主持人！`,
          duration: MESSAGE_DURATION.LONG,
        });
        return;
      }
      if (!isRoomExist && this.userInfo.userRole == USER_ROLE_MAJOR) {
        console.log('房间不存在，主持人创建房间！--------------------------------');
        this.setTUIRoomData('createRoom');
        this.routerRoom({roomId:roomId})
        return;
      }
      this.setTUIRoomData('enterRoom');
      this.routerRoom({roomId:roomId})
    },
    // 处理用户点击页面左上角【退出登录】
    handleLogOut() {
      // 接入方处理 logout 方法
    },
    routerRoom(querys){
      // 跳转房间
      this.$router.push({
        path: '/room',
        query: {
          roomId: querys.roomId,
        },
      });
    },
    routerLogin(){
      // 跳转房间
      this.$router.push({
        path: '/login',
      });
    },

  },
};
</script>

<style lang="scss" scoped>
.home-container {
  width: 100%;
  height: 100%;
  color: #B3B8C8;
  font-family: PingFangSC-Medium;
  background: url(@/assets/img/beijing.jpg) center no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 405px;
  flex-direction: column;
  justify-content: center;
  .header {
    width: 100%;
    padding: 10px 35px;
    display: flex;
    align-items: center;
    .header-item {
      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }
  .home-herd{
    display: flex;
    //margin-top: 100px;
    .admin-commnet{
      display: flex;
      justify-content: center;
      // align-items: center;
      align-items: flex-end;
      width: 100%;
    }
  }

  .footer-beian{
    display: flex;
    width: 100%;
    padding-top: 10px;
    margin-top: 100px;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    a{
      display:inline-block;
      text-decoration:none;
      height:20px;
      line-height:20px;
      p{
        color: #0d0f15;
        height:20px;
        line-height:20px;
        margin: 0px 0px 0px 5px;
      }
    }
  }
}
@media screen and (max-width: 900px){
  .admin-commnet-box{
    width: 410px;
  }
}


</style>
