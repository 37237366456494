import Vue from 'vue';
import VueRouter from 'vue-router';
import StorageUtils from "@/putils/StorageUtils"
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import Player from '@/views/player';
import Pusher from '@/views/pusher';
import Playback from '@/views/playback';
import Login from '@/views/login.vue';
import Admin from '@/views/admin.vue';
import Index from '@/views/index.vue';
import Room from '@/views/admin/room';
import Logins from '@/views/whiteboard/Login.vue';


import dome from '@/views/dome.vue';
import { CONFIGLIST } from "@/constants/localKeys";

Vue.use(VueRouter);

const whiteList = [ '/',"/index",'/login']

const routes = [

  //默认不需要权限的页面
  // {
  //   path: '/',
  //   name:'Logins',
  //   component: Logins,
  // },
  // {
  //   path: '/whiteboard',
  //   name: 'whiteboard',
    
  //   component: () => import(/* webpackChunkName: "about" */ '../views/whiteboard/Main.vue')
  // },
  {
    path: '/',
    name:'Index',
    component: Index,
  },
  {
    path: '/commercialCopyright',
    name: 'commercialCopyright',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/commercialCopyright.vue')
  },
  {
    path: '/chCooper',
    name: 'chCooper',
    component: () => import(/* webpackChunkName: "about" */ '../views/chCooper.vue')
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
  },
  {
    path: '/Logins',
    name: 'Logins',
    component: () => import(/* webpackChunkName: "about" */ '../views/Logins.vue')
  },
  {
    path: '/wx',
    name: 'wx',
    component: () => import(/* webpackChunkName: "about" */ '../views/wx.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue')
  },
  {
    path: '/Metaverse',
    name: 'Metaverse',
    component: () => import(/* webpackChunkName: "about" */ '../views/live/Metaverse.vue')
  },
  {
    path: '/medical',
    name: 'medical',
    component: () => import(/* webpackChunkName: "about" */ '../views/live/medical.vue')
  },

  //登录页面
  {
    path: '/login',
    name:'login',
    component: Login,
  },
  //检测是否为微信登陆
  {
    path: '/check',
    name:'check',
    component: () => import('@/views/check'),
  },

  // 场控页 登录页
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    children: [
      {
        path: '/admin',
        component: () => import('@/views/admin'),
      }
    ],
  },

  {
    path: '/room',
    name: 'room',
    component: Room,
    children: [
      {
        path: '/room',
        component: () => import('@/views/admin/room'),
      },
    ],
  },
  // 观众端页面
  //观众进入房间之前的页面
  {
    path: '/watch',
    name:'watch',
    component: () => import('@/views/watch'),
  },
  {
    path: '/player',
    name: 'player',
    component: Player,
    children: [
      {
        path: '/player',
        name: '观众页',
        component: () => import('@/views/player/index'),
      },
    ],
  },
  {
    path: '/pusher',
    component: Pusher,
    redirect: '/pusher',
    children: [
      {
        path: '/pusher',
        name: '主播播放',
        component: () => import('@/views/pusher/index.vue'),
      },
    ],
  },
  {
    path: '/playback',
    component: Playback,
    redirect: '/playback',
    children: [
      {
        path: '/playback',
        name: '主播播放',
        component: () => import('@/views/playback/index.vue'),
      },
    ],
  },

  {
    path: '*',
    redirect: '/404'
  }
];


const router = new VueRouter({
  // linkActiveClass: 'active',
  mode: 'hash',
  // mode: 'history',
  base: '/',
  routes,
});

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start();


  let locKey = {userid:to.query.userId};
  const token = StorageUtils.getSessionItem(CONFIGLIST.SESSION_WEB_TOKENINFO, locKey);
  let validator = typeof to.meta == "undefined" || !to.meta.auth || token;
  if (validator){
    //判断是否是登录
    let result = validator ? {} : {
      name: "login",// 跳转到命名路由
      query: {
        url: to.fullPath,// 做一个来源页面，用于登陆成功之后跳转
      }
    };
    next(result);
    NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
  }else {
    /* has no token*/
    //如果token不存在，判断是否存在白名单中
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})


export default router;
